import { useState, useEffect, useRef, useMemo } from 'react';
import Keycloak from 'keycloak-js';
import { createCookie, deleteCookie } from '@kamet/common';
import { persistor } from '../../../redux/store';

let client: any = null;
const env = 'qa';
const useAuth = (onCompletion?: () => void) => {
  const isRun = useRef(false);
  const [isLogin, setLogin] = useState(false);

  client = useMemo(
    () =>
      new Keycloak({
        realm: `kamet-${env}`,
        url: `https://auth.${env}.kamet.ai`,
        clientId: `kamet-${env}-client`,
      }),
    []
  );

  useEffect(() => {
    if (isRun.current) return;
    isRun.current = true;

    // TODO tag. Please assign value from a env file or secret service

    client &&
      client
        .init({ onLoad: 'login-required' })
        .then((authenticated: any) => {
          setLogin(authenticated);
          if (authenticated) {
            const accessToken = client.token;
            const refreshToken = client.refreshToken;

            if (accessToken) {
              createCookie('access_token', accessToken, {
                days: null,
                secure: true,
                sameSite: 'strict',
              });
            } else {
              console.error('Access Token is undefined');
            }

            if (refreshToken) {
              createCookie('refresh_token', refreshToken, {
                days: null,
                secure: true,
                sameSite: 'strict',
              });
            } else {
              console.error('Refresh Token is undefined');
            }

            setTimeout(() => {
              client
                .updateToken(70)
                .then((refreshed: any) => {
                  if (refreshed && client.token) {
                    createCookie('access_token', client.token, {
                      days: null,
                      secure: true,
                      sameSite: 'strict',
                    });
                  } else {
                    console.error('Token was not refreshed');
                  }
                })
                .catch((err: any) => {
                  console.error('Failed to refresh token:', err);
                  client.logout();
                });
            }, 60000);

            onCompletion && onCompletion();
          } else {
            console.error('Keycloak login failed');
            // TODO tag Navigate to error page
          }
        })
        .catch((err: any) => {
          console.error('Keycloak initialization error:', err);
        });
  }, []);
  return isLogin;
};

const clearKeycloakCookies = () => {
  const keycloakCookies = [
    'KEYCLOAK_IDENTITY',
    'KEYCLOAK_SESSION',
    'KEYCLOAK_IDENTITY_LEGACY',
    'KC_RESTART',
    'AUTH_SESSION_ID_LEGACY',
    'AUTH_SESSION_ID',
  ];
  keycloakCookies.forEach((cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

const logout = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .trim()
      .split('=')
      .reduce(
        (key) => `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`,
        ''
      );
    clearKeycloakCookies();
    deleteCookie('access_token');
    deleteCookie('refresh_token');
    localStorage.clear();
    //client.logout({ redirectUri: process.env.NODE_ENV === 'development' ? `https://codelynks.dev.kamet.ai/`: '/api' });
    client.logout({ redirectUri: window.location });
  });
};

export { useAuth, logout };
